
import Formulario from './components/formulario';

function App() {
  return (
    <Formulario />
  );
}

export default App;
