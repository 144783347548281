import moment from 'moment';
import URL_ROUTE from '../constants/index';

class Api {
    async validateForm(token) {
        let data = {token}
        const query = await fetch(`${URL_ROUTE.VALIDATEFORM}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveForm(token, signature, methodrevoke, phone, email, agentname, agentnumber, agentphone, agentemail, agencyname, agencynumber, agencyowner, agencyphone, agencyaddress, email_receives, picture, language, ip) {
        let newDate = new Date()
        let data = {
            token, signature, methodrevoke, name: methodrevoke, phone, email, agentname, agentnumber, agentphone, agentemail, agencyname, agencynumber, agencyowner, agencyphone, agencyaddress, dateexpiration: moment(newDate).add(1, 'year').format('YYYY-MM-DD'), email_receives, picture, language, ip
        }
        const query = await fetch(`${URL_ROUTE.SAVEFORM}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

}

export default new Api();
