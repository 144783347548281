import Swal from 'sweetalert2';


export const alert = (title, message, type, textButton) => {
    Swal.fire({
        title: title,
        text: message,
        icon: type,
        confirmButtonText: textButton
    })
}